import { message } from 'antd';
import type { FormInstance } from 'antd/es/form';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import React from 'react';
import type { OptionsInterface, UserInfoInterface } from './interface';
import { api } from '../../utils';

export default class Model {
  constructor() {
    this.__init__();
  }

  @observable public pic = '';

  @observable public formRef = React.createRef<FormInstance>();

  @observable public findAll: OptionsInterface[] = [];// 省

  @observable public findByProvinceId: OptionsInterface[] = [];// 市

  @observable public findByCityId: OptionsInterface[] = []; // 区

  @observable public loading = false;

  @observable public uploadLoading = false;

  @action
  public __init__ = async() => {
    const req = await request<BaseData<UserInfoInterface>>({
      method: 'GET',
      url: '/api/iac/user/info',
    });

    const {
      username,
      mobile,
      email,
      showName,
      address,
      districtId,
      cityId,
      provinceId,
      pic,
    } = req.data;
    await this.getFindAll();
    provinceId && await this.getFindByProvinceId(`${provinceId}`);
    cityId && await this.getFindByCityId(`${cityId}`);
    this.pic = pic;
    this.formRef?.current?.setFieldsValue({
      username,
      mobile,
      email,
      showName,
      address,
      districtId: districtId ? `${districtId}` : undefined,
      cityId: cityId ? `${cityId}` : undefined,
      provinceId: provinceId ? `${provinceId}` : undefined,
    });
  };

  @action
  public onSubmit = () => {
    this.formRef?.current?.validateFields()
      ?.then((values) => {
        this.loading = true;
        request<BaseData>({
          method: 'POST',
          url: '/api/iac/user/info/update',
          data: {
            ...values,
            name: values.showName,
            pic: this.pic,
          },
        })
          .then((res) => {
            message.success(res.info);
          })
          .finally(() => {
            this.loading = false;
          });
      });
  };

  @action
  public getFindAll = async(): Promise<void> => {
    const req = await request<BaseData<Array<{ provinceId: number; provinceName: string; }>>>({
      method: 'GET',
      url: api.queryProvince,
    });
    this.findAll = this.getOptions(req.data, 'id', 'provinceName');
  };

  @action
  public getFindByProvinceId = async(provinceId: string): Promise<void> => {
    const req = await request<BaseData<Array<{ provinceId: number; provinceName: string; }>>>({
      method: 'GET',
      url: api.queryCity,
      params: { provinceId },
    });
    this.findByProvinceId = this.getOptions(req.data, 'id', 'cityName');
  };

  @action
  public getFindByCityId = async(cityId: string): Promise<void> => {
    const req = await request<BaseData<Array<{ provinceId: number; provinceName: string; }>>>({
      method: 'GET',
      url: api.queryDistrict,
      params: { cityId },
    });
    this.findByCityId = this.getOptions(req.data, 'id', 'districtName');
  };

  @action
  public setHome = (value, key) => {
    if (key === 'provinceId') {
      this.getFindByProvinceId(value);
      this.formRef.current.setFieldsValue({
        cityId: undefined,
        districtId: undefined,
      });
    }
    if (key === 'cityId') {
      this.getFindByCityId(value);
      this.formRef.current.setFieldsValue({ districtId: undefined });
    }
  };

  @action
  public onUploadChange = (url: string) => {
    this.pic = url;
    if (url) {
      request<BaseData>({
        method: 'POST',
        url: '/api/iac/user/info/updateUserInfo',
        data: { pic: url },
      });
    }
  };

  @action
  public setUploadLoading = (uploadLoading: boolean) => {
    this.uploadLoading = uploadLoading;
  };

  public getOptions = (data: Array<{[key: string]: any; }>, value: string, label: string): Array<{ label: string; value: string; }> => {
    return data.map((item) => {
      return {
        label: item[label],
        value: `${item[value]}`,
      };
    });
  };
}
