import { Button, Form, Input, Select, Upload } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { getStaticResourceUrl, singleUpload } from 'egenie-common';
import styles from './index.less';
import Store from './model';

import type { RcFile } from 'antd/lib/upload';

const noHead = `${getStaticResourceUrl('pc/ts/egenie-ts-vogue/images/defaultUser.png')}`;
@observer
export default class Information extends Component {
  constructor(prop) {
    super(prop);
    this.store = new Store();
  }

  private readonly store: Store;

  render() {
    const { onSubmit, onUploadChange, setUploadLoading, uploadLoading, formRef, pic, findAll, loading, findByCityId, findByProvinceId, setHome } = this.store;
    return (
      <div className={styles.page}>
        <div>
          <div className={styles.info}>
            <Form
              colon={false}
              labelCol={{ span: 24 }}
              ref={formRef}
              wrapperCol={{ span: 24 }}
            >
              <Form.Item
                label="用户名:"
                name="username"
              >
                <Input disabled/>
              </Form.Item>
              <Form.Item
                label="手机号:"
                name="mobile"
              >
                <Input disabled/>

              </Form.Item>
              <Form.Item
                label="邮箱:"
                name="email"
              >
                <Input/>
              </Form.Item>
              <Form.Item
                label="真实姓名:"
                name="showName"
                rules={[
                  {
                    required: true,
                    message: '请输入真实姓名',
                  },
                ]}
              >
                <Input/>
              </Form.Item>
              <Form.Item
                className={styles.home}
                label="所在地:"
                required
              >
                <Form.Item
                  name="provinceId"
                  rules={[
                    {
                      required: true,
                      message: '请选择省',
                    },
                  ]}
                >
                  <Select
                    onChange={(value) => {
                      setHome(value, 'provinceId');
                    }}
                    options={findAll}
                    placeholder="省"
                    style={{ marginRight: 5 }}
                  />
                </Form.Item>
                <Form.Item
                  name="cityId"
                  rules={[
                    {
                      required: true,
                      message: '请选择市',
                    },
                  ]}
                >
                  <Select
                    onChange={(value) => {
                      setHome(value, 'cityId');
                    }}
                    options={findByProvinceId}
                    placeholder="市"
                    style={{ marginRight: 5 }}
                  />
                </Form.Item>
                <Form.Item
                  name="districtId"
                  rules={[
                    {
                      required: true,
                      message: '区',
                    },
                  ]}
                >
                  <Select
                    options={findByCityId}
                    placeholder="区"
                  />
                </Form.Item>
              </Form.Item>
              <Form.Item
                label="街道地址:"
                name="address"
                rules={[
                  {
                    required: true,
                    message: '请输入街道地址',
                  },
                ]}
              >
                <Input/>
              </Form.Item>
              <Button
                loading={loading}
                onClick={onSubmit}
                type="primary"
              >
                更新信息
              </Button>
            </Form>
          </div>
          <div className={styles.portrait}>
            <span>
              头像：
            </span>
            <div className={styles.pic}>
              <img
                onDragStart={(e) => e.preventDefault()}
                src={pic || noHead}
                style={!pic ? { width: 75 } : undefined}
              />
            </div>

            <Upload
              accept="image/*"
              customRequest={async(data) => {
                const file = data.file as RcFile;
                setUploadLoading(true);
                const url = await singleUpload({ obsConfigId: 33 }, file, { fileName: file.name });
                setUploadLoading(false);
                onUploadChange(url);
              }}
              disabled={uploadLoading}
              showUploadList={false}
              withCredentials
            >
              <Button
                className={styles.replacePortrait}
                loading={uploadLoading}
              >
                更换头像
              </Button>
            </Upload>
          </div>
        </div>
      </div>
    );
  }
}
